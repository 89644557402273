<template>
  <div
    class="mr-1 align-self-center container--report"
    style="position: relative;"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      role="button"
      @click="isShow = !isShow"
      id="button--filter__report"
    >
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="ChevronLeftIcon"
      />
      <h6 class="mx-2 mb-0 d-flex align-items-center text-dark fw-bold-700">
        <feather-icon
          class="mr-25 text-dark fw-bold-700"
          size="20"
          icon="CalendarIcon"
        />
        {{ filter_date ? moment(filterDateCustom.from).format('DD MMM YYYY') + '-' + moment(filterDateCustom.to).format('DD MMM YYYY') : 
            moment(start_date).format('DD MMM YYYY') + ' - ' + moment(end_date).format('DD MMM YYYY') }}
        <!-- Juli 2022 -->
      </h6>
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="ChevronRightIcon"
      />
    </div>
    <Transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isShow"
        class="bg-white content__filter"
      >
        <div class="d-flex justify-content-between align-items-center py-1">
          <div
            class="px-1 custom__dropdown--item"
            role="button"
            @click="isShow = false"
            id="button--filter__report__close"
          >
            <feather-icon
              size="16"
              class="text-dark fw-bold-800"
              style="margin-right: 5px;"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark fw-bold-700 size14">
              Filter Data
            </span>
          </div>
          <b-button
            id="button--filter__report__submit"
            class="mr-1"
            @click="submitFilter"
          >
            Terapkan
          </b-button>
        </div>
        <hr>
        <div class="">
          <keep-alive>
            <b-tabs
              class="mb-0"
              content-class="bg-light bg-background p-2 border-radius-bottom-right-16 border-radius-bottom-left-16"
            >
              <b-tab title="Laporan Bulanan" @click="type = 'bulanan'">
                <!-- @click="start_date = moment(new Date()).startOf('month').format('YYYY-MM-DD'), end_date = moment(new Date()).endOf('month').format('YYYY-MM-DD')" -->
                <h2 class="text-dark fw-bold-700 text-center">
                  {{ moment(new Date()).startOf('month').format('DD MMM') }} - {{ moment(new Date()).endOf('month').format('DD MMM') }}
                </h2>
              </b-tab>
              <b-tab title="Atur Sendiri" @click="type = 'custom'">
                <b-form-radio
                  @change="cleanUpForm"
                  name="some-radios"
                  class="mb-1"
                >
                  <span class="text-dark fw-bold-600 size14">Pilih Tanggal</span>
                </b-form-radio>
                <div class="d-flex justify-content-between" v-if="isCustom">
                  <div>
                    <label
                      for="first-date-datepicker"
                      class="text-dark size12 fw-bold-600"
                    >Tanggal Awal</label>
                    <b-form-datepicker
                      id="first-date-datepicker"
                      v-model="start_date"
                      class="size12 text-dark fw-bold-600 bg-light bg-line"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      placeholder="DD/MM/YYYY"
                      locale="id"
                    />
                  </div>
                  <div>
                    <label
                      for="second-date-datepicker"
                      class="text-dark size12 fw-bold-600"
                    >Tanggal Akhir</label>
                    <b-form-datepicker
                      id="second-date-datepicker"
                      v-model="end_date"
                      class="size12 text-dark fw-bold-600 bg-light bg-line"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      placeholder="DD/MM/YYYY"
                      locale="id"
                    />
                  </div>
                </div>
                <hr>
                <!-- Rentang Waktu -->
                <div class="">
                  <h6 class="text-dark size14 fw-bold-600 mb-2">
                    Pilih rentang waktu
                  </h6>
                  <!-- <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">2 Bulan terakhir</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">3 Bulan terakhir</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(6, 'months').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">6 Bulan terakhir</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(1, 'years').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">Tahun lalu</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(2, 'years').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">{{ moment(new Date()).subtract(2, 'years').format('YYYY') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(3, 'years').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">{{ moment(new Date()).subtract(3, 'years').format('YYYY') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    :value="moment(new Date()).subtract(4, 'years').format('YYYY-MM-DD')"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">{{ moment(new Date()).subtract(4, 'years').format('YYYY') }}</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="start_date"
                    name="some-radios"
                    value="2021-01-01"
                    class="mb-1"
                    @change="changeEndDate"
                  >
                    <span class="text-dark fw-bold-600 size14">Semua</span>
                  </b-form-radio> -->
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="this_week"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Minggu Ini</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="last_week"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Minggu Kemarin</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="this_month"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Bulan Ini</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="last_month"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Bulan Kemarin</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="this_year"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Tahun Ini</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="last_year"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Tahun Kemarin</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="q1"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Kuartal 1</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="q2"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Kuartal 2</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="q3"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Kuartal 3</span>
                  </b-form-radio>
                  <b-form-radio
                    v-model="filter_date"
                    name="some-radios"
                    value="q4"
                    class="mb-1"
                    @change="setCustom"
                  >
                    <span class="text-dark fw-bold-600 size14">Kuartal 4</span>
                  </b-form-radio>
                </div>
                <hr/>
                <div>
                  <b-form-group>
                    <v-select
                      id="v-produk"
                      v-model="product_uuid"
                      placeholder="Pilih Nama SKU"
                      class="custom__input"
                      label="title"
                      :options="optionsProduk"
                      @search="onSearchProduk"
                    />
                  </b-form-group>
                </div>
              </b-tab>
            </b-tabs>
          </keep-alive>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  BButton, VBToggle, BTabs, BTab, BFormDatepicker, BFormRadio, BCollapse, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    BButton,
    BTabs,
    BTab,
    BFormDatepicker,
    BFormRadio,
    BCollapse,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    styleName: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
    },
    optionsProduk: {
      type: Array,
    },
    getProduk: {
      type: Function
    },
    filterDate: {
      type: Object,
    },
  },
  data() {
    const newData = new Date()
    const startData = moment(newData).startOf('month').format('YYYY-MM-DD')
    const endData = moment(newData).endOf('month').format('YYYY-MM-DD')
    return {
      moment,
      start_date: startData,
      end_date: endData,
      isShow: false,
      isCustom: false,
      type: 'bulanan',
      product_uuid: '',
      filter_date: '',
      filterDateCustom: {},
    }
  },
  watch: {
    filter: {
      handler(value) {
        this.start_date = value.start_date
        this.end_date = value.end_date
      },
      deep: true,
    },
    filterDate(newValue) {
      this.filterDateCustom = newValue;
    },
  },
  methods: {
    submitFilter() {
      switch (this.type) {
        case 'bulanan':
          this.$emit('startDate', moment(new Date()).startOf('month').format('YYYY-MM-DD'))
          this.$emit('endDate', moment(new Date()).endOf('month').format('YYYY-MM-DD'))
          this.$emit('productUuid', this.product_uuid);
          this.type = 'bulanan'
          this.isCustom = false;
          this.isShow = false
          break
        case 'custom':
          this.$emit('startDate', this.start_date)
          this.$emit('endDate', this.end_date)
          this.$emit('productUuid', this.product_uuid);
          this.$emit('filterDate', this.filter_date)
          this.type = 'bulanan'
          this.isCustom = false;
          this.isShow = false
          break
        default:
          break
      }
    },
    cleanUpForm() {
      this.isCustom = true;
      this.filter_date = '';
    },
    setCustom() {
      this.isCustom = false;
    },
    changeEndDate() {
      this.end_date = moment(new Date()).format('YYYY-MM-DD')
    },
    onSearchProduk(search, loading) {
      if (search.length) {
        loading(true)
        this.searchProduk(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionsProduk = []
          this.getProduk()
          loading(false)
        }, 500)
      }
    },
    searchProduk: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('cashier/getData', {
        uuid: '',
        params: {
          search,
        },
      })
        .then(response => {
          vm.optionsProduk = response.data.data.data.map(e => {
            return {
              label: e.uuid,
              title: e.name
            }
          })
          loading(false)
        }).catch(error => {
          loading(false)
        })
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.nav-tabs {
  margin-bottom: 0;
  padding: 0 1rem !important;
}
.content__filter {
  position: absolute;
  z-index: 999;
  width: 311px;
  border-radius: 16px;
  top: 40px;
  margin-top: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.input-group {

  input {
    background: #F1F2F5;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
  }

  .input-group-append {
    .input-group-text {
      background-color: #F1F2F5;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: none;
    }
  }
}
</style>
